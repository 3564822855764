import { Device } from 'mediasoup-client';
import socketIO from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
console.log('REQUIRE')
let socket = null;
let device = null;

let producer = {
    id: null,
    transport: null,
    audioProducer: null,
    videoProducer: null,
    produceCallbacks: {},
    connectProducerTransportCallback: null,

};

let consumeTracer = {};

let consumers = {};

let videoConsumerStatus = {};
let videoConsumerState = {};
let layers = {};


const clearState = () => {
    try {
        socket.removeAllListeners('createProducerTransport');
        socket.removeAllListeners('consume');
        socket.removeAllListeners('rtpCapabilities');
        socket.removeAllListeners('volumes');
        socket.removeAllListeners('produce');
        socket.removeAllListeners('resume');
        socket.removeAllListeners('connectConsumerTransport');
        socket.removeAllListeners('createConsumerTransport');
        socket.removeAllListeners('connectProducerTransport');

        socket.close()
    }catch(e) { 
        console.log(e);
    }


    producer = {
        id: null,
        transport: null,
        audioProducer: null,
        videoProducer: null,
        produceCallbacks: {},
        connectProducerTransportCallback: null,

    };
    consumeTracer = {};
    consumers = {};
    videoConsumerStatus = {};
    videoConsumerState = {};
    socket = null;
    device = null;

}


const loadDevice = async (routerRtpCapabilities) => {
    try {
        device = new Device();
    } catch (error) {
        if (error.name === 'UnsupportedError') {
            console.error('browser not supported');
        }
    }
    await device.load({ routerRtpCapabilities });

    socket.emit('createProducerTransport', {});
}


const loadConsumerDevice = async (routerRtpCapabilities) => {
    let consumerDevice = null;

    try {
        consumerDevice = new Device();
    } catch (error) {
        if (error.name === 'UnsupportedError') {
            console.error('browser not supported');
        }
    }
    await consumerDevice.load({ routerRtpCapabilities });
    return consumerDevice;
}


const consume = async (pid, trackKind) => {
    //console.log('--start of consume --kind=' + trackKind);
    const { rtpCapabilities } = device;
    //const data = await socket.request('consume', { rtpCapabilities });
    // const data = await sendRequest('consume', { rtpCapabilities: rtpCapabilities, kind: trackKind })

    consumeTracer[pid + trackKind] = {
        ts: new Date().getTime() / 1000,
        producer: pid,
        kind: trackKind,
        receivedTrack: false
    }
    socket.emit('consume', { producer: pid, rtpCapabilities: rtpCapabilities, kind: trackKind });
}


const init = (mediaServer, authToken, id, stream, consumerCallback, volumesCallback, reconnectCallback, screenStream=null) => {
    console.log('init', window.location)
    // let interval = setInterval(() => {
    //     let ts = new Date().getTime() / 1000;
    //     for (var key in consumeTracer) {
    //         if (!consumeTracer[key].receivedTrack && ts - consumeTracer[key].ts > 4) {
    //             consume(consumeTracer[key].producer, consumeTracer[key].kind);
    //         }
    //     }
    // }, 1000);


    producer.id = id;
    //console.log('producer id', producer.id)

    socket = socketIO.connect(mediaServer, { transports: ['websocket', 'polling'], query: { token: authToken } })

    socket.on('connect', () => {
        //console.log('connected');
    })

    socket.on('rtpCapabilities', (data) => {
        //console.log('rtpCapabilities', data)
        loadDevice(data.rtpCapabilities);
    })

    socket.on('connectProducerTransport', (data) => {
        if (data._producer != producer.id) {
            return;
        }

        delete data._producer;

        //console.log('connectProducerTransport', data);
        producer.connectProducerTransportCallback(data);
        producer._connected = true;
    })


    socket.on('volumes', (data) => {

        if (producer && data.length && data.length == 2 && data[0].id == producer.id) {
            let tmp = { ...data[0] };
            data[0] = data[1];
            data[1] = tmp;
        }

        volumesCallback(data)
    })

    socket.on('produce', (data) => {
        if (data._producer != producer.id) {
            return;
        }

        delete data._producer;


        if (producer.produceCallbacks && producer.produceCallbacks[data.kind]) {
            producer.produceCallbacks[data.kind]({ id: data.id });
        }

        

    })

    socket.on('createProducerTransport', async (data) => {
        //console.log('create producer transport', data)

        if (data._producer != producer.id) {
            return;
        }

        delete data._producer;

        // if (producer.transport) {
        //     return;
        // }

        producer.transport = device.createSendTransport(data);
        //console.log('createSendTransport:', producer.transport);
        producer.transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
            //console.log('--trasnport connect');

            producer.connectProducerTransportCallback = callback;

            socket.emit('connectProducerTransport', {
                dtlsParameters: dtlsParameters
            });
        });

        producer.transport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
            //console.log('--trasnport produce');
            try {
                producer.produceCallbacks[kind] = callback;

                socket.emit('produce', {
                    transportId: producer.transport.id,
                    kind,
                    rtpParameters,
                });



            } catch (err) {
                //console.log(err);
                errback(err);
            }
        });

        producer.transport.on('connectionstatechange', (state) => {
            switch (state) {
                case 'connecting':
                    //console.log('publishing...');
                    break;

                case 'connected':
                    //console.log('published');
                    break;

                case 'failed':
                    //console.log('failed');
                    producer.transport.close();

                    if (reconnectCallback){
                        reconnectCallback();
                    }


                    // setTimeout(() => {
                    //     socket.emit('createProducerTransport', {});
                    // }, 3000);
                    break;

                default:
                    break;
            }
        });

        if (screenStream){
            producer.videoProducer = await producer.transport.produce({ track: screenStream.getVideoTracks()[0], encodings: [{ scaleResolutionDownBy: 2, maxBitrate: 540000 }, { scaleResolutionDownBy: 1, maxBitrate: 2500000 }], stopTracks: false });

        }else{
            producer.videoProducer = await producer.transport.produce({ track: stream.getVideoTracks()[0], encodings: [{ scaleResolutionDownBy: 2, maxBitrate: 540000 }, { scaleResolutionDownBy: 1, maxBitrate: 2500000 }], stopTracks: false });
        }
        //producer.audioProducer = await producer.transport.produce({ track: stream.getAudioTracks()[0], });
    })


    socket.on('resume', (data) => {
        if (producer.id != data._consumer) {
            return;
        }


        if (data.notReady) {

            setTimeout(() => {
                socket.emit('resume', { kind: data.kind, producer: data.producer })

            }, 1000);
        }
    })


    socket.on('connectConsumerTransport', (data) => {
        return;
        if (data._consumer != producer.id) {
            return;
        }
        delete data._consumer;


        let _producer = data.producer;
        delete data.producer;
        if (consumers[_producer] && consumers[_producer].createConsumerTransportCallback)
            consumers[_producer].createConsumerTransportCallback(data);
    })


    socket.on('consume', async (data) => {
        return;
        if (!consumers[data.producer]) {
            return;
        }

        //console.log('||||||||||||||||||||||||||' , data._consumer, producer.id)

        if (data._consumer != producer.id) {
            return;
        }

        delete data._consumer;

        const {
            producerId,
            id,
            kind,
            rtpParameters,

        } = data;
        let _producer = data.producer;

        if (!consumers[_producer]) {
            return;
        }

        console.log('consume', data.producer, data.kind, data._consumer, producer.id)

        if (producerId) {
            let codecOptions = {};
            const consumer = await consumers[_producer].transport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions,
            });
            //const stream = new MediaStream();
            //stream.addTrack(consumer.track);

            //console.log(consumer.track);
            //addRemoteTrack(clientId, consumer.track);

            consumeTracer[data.producer + data.kind].receivedTrack = true;


            consumerCallback({
                id: _producer,
                track: consumer.track
            })


            //console.log('--end of consume');
            //return stream;

            if (consumer) {
                //console.log('-- track exist, consumer ready. kind=' + kind);
                if (kind === 'video') {
                    //console.log('-- resume kind=' + kind);
                    videoConsumerStatus[_producer] = true;

                    if (videoConsumerState[_producer]) {
                        socket.emit('resume', { kind: kind, producer: _producer })
                    }

                    //socket.emit('resume', { kind: kind, producer: _producer })
                }
                else {
                    //console.log('-- resume kind=' + kind);

                    socket.emit('resume', { kind: kind, producer: _producer })
                }
            }
            else {
                //console.log('-- no consumer yet. kind=' + kind);
                return null;
            }
        }
        else {
            console.warn('--- remote producer NOT READY');
            setTimeout(() => {
                consume(_producer, kind);
            }, 5000);
            return null;
        }

    })


    socket.on('createConsumerTransport', async (data) => {
        return;
        console.log('createConsumerTransport', data, producer.id)

        if (!data.producer) {
            console.log('---1---')
            return;
        }

        if (data.producer == producer.id) {
            console.log('---2---')
            return;
        }


        if (data._consumer != producer.id) {
            console.log('---3---')
            return;
        }
        //delete data._consumer;



        //console.log('createConsumerTransport', data)


        let _producer = data.producer;
        videoConsumerStatus[_producer] = false;

        // delete data.producer;
        if (consumers[_producer] && consumers[_producer].transport) {
            consumers[_producer].transport.close();
            delete consumers[_producer].transport;
            delete consumers[_producer];
        }

        consumerCallback({
            id: _producer,
            status: 'connecting',
            _new: true
        })


        consumers[_producer] = {
            device: await loadConsumerDevice(data.rtpCapabilities),
            transport: null,
            videoConsumer: null,
            audioConsumer: null,
            createConsumerTransportCallback: null,

        }

        consumers[_producer].transport = consumers[_producer].device.createRecvTransport(data);
        //console.log('createConsumerTransport:', consumers[_producer].transport);
        consumers[_producer].transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
            //console.log('--trasnport connect');
            consumers[_producer].createConsumerTransportCallback = callback;
            socket.emit('connectConsumerTransport', { producer: _producer, dtlsParameters: dtlsParameters })
        });
        let uuid = uuidv4();
        consumers[_producer].transport.on('connectionstatechange', (state) => {
            console.log("connectionstatechange", state, _producer, uuid)
            switch (state) {
                case 'connecting':
                    consumerCallback({
                        id: _producer,
                        status: 'connecting'
                    })

                    //console.log('subscribing...');
                    break;

                case 'connected':
                    //console.log('subscribed');
                    consumerCallback({
                        id: _producer,
                        status: 'connected'
                    })

                    break;

                case 'disconnected':
                    //console.log('disconnected');
                    consumerCallback({
                        id: _producer,
                        status: 'disconnected'
                    })

                    //producerTransport.close();
                    break;
                case 'closed':
                    //console.log('closed');
                    consumerCallback({
                        id: _producer,
                        status: 'closed'
                    })

                    //producerTransport.close();
                    break;

                case 'failed':
                    //console.log('failed');
                    consumerCallback({
                        id: _producer,
                        status: 'failed'
                    })


                    delete consumers[_producer];

                    //producerTransport.close();
                    break;


                default:
                    break;
            }
        });
        consume(_producer, 'video');
        consume(_producer, 'audio');


    })


}

const replaceStream = (stream) => {
    //producer.videoProducer = await producer.transport.produce({ track: stream.getVideoTracks()[0], encodings: [{ scaleResolutionDownBy: 4, maxBitrate: 100000 }, { scaleResolutionDownBy: 1 }] });
    if (producer && producer.videoProducer)
        producer.videoProducer.replaceTrack({ track: stream.getVideoTracks()[0] });

    if (producer && producer.audioProducer)
        producer.audioProducer.replaceTrack({ track: stream.getAudioTracks()[0] });

}

const replaceVideoStream = (stream) => {
    if (producer && producer.videoProducer)
        producer.videoProducer.replaceTrack({ track: stream.getVideoTracks()[0] });
}


const changeLayer = (id, layer) => {

    if (layers[id] == layer) {
        return;
    }

    socket.emit('changeLayer', { producer: id, layer })
    layers[id] = layer;
}

const playVideo = (id) => {

    let cnt = 0;
    for (var key in videoConsumerState) {
        if (videoConsumerState[key])
            cnt++;
    }

    if (cnt >= 20){
        return;
    }

    if (!videoConsumerState[id]) {
        if (videoConsumerStatus[id])
            socket.emit('resume', { kind: 'video', producer: id })
        videoConsumerState[id] = true;
    }
}
const pauseVideo = (id) => {
    if (videoConsumerState[id]) {
        if (videoConsumerStatus[id])
            socket.emit('pause', { kind: 'video', producer: id })
        videoConsumerState[id] = false;
    }
}

const disconnect = () => {
    socket.close();
}

export default  {
    init,
    changeLayer,
    replaceStream,
    replaceVideoStream,
    playVideo,
    pauseVideo,
    clearState,
    disconnect
}