import React, { Component } from 'react';

import logo from './logo.svg';
import video from './mov_bbb.mp4';

import './App.css';
import VideoGateway from './videoGateway';
import tokens from './tokens.json';

const conversationId = '63f60e4db8185022997bd61d';
let mediaServerURL = 'https://media02.curoflow.se'



class App extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      count: '1',
      arr: []
    }
  }

  componentDidMount() {
  }

  initStream = () => {
    if (this.didMount){
      return;
    }

    this.didMount =true;
    if (window.location.search.indexOf('index') !== -1){
      console.log('did MOUNT')
      let index =Number( window.location.search.split('index=').pop());
      setTimeout(() => {
        this.stream = this.video.captureStream();

        setTimeout(() => {
          VideoGateway.init(mediaServerURL, tokens[index].authToken, tokens[index].uid, this.stream, (consumer) => {
              
          }, (volumes) => {
      
          }, () => {
      
          }, null);
    
        }, 1000)
      }, 1000);

  

    }

  }

  start = () => {

    let arr = [];
    for(let i=0;i<Number(this.state.count);i++){
      arr.push({
        authToken: tokens[tokens.length - 1 - i].authToken,
        uid: tokens[ tokens.length  - 1 - i].uid
      })
    }
    this.setState({
      arr
    })
  }


  render() {
  return (
    <div className="App">
      { window.location.search.indexOf('index') !== -1 ?
            <video onLoadedMetadata={() => this.initStream()} autoPlay muted loop ref={(node) => this.video = node}>
        <source type="video/mp4" src={video}/>
      </video>
      :
      null
  }

      {
        this.state.arr.map((item, idx) => {
          return (
            <iframe key={idx} src={"/?index="+idx} />
          )
        })
      }
      {window.location.search.indexOf('index') === -1 ?
      <>
      <br></br>
      <input type="text" value={this.state.count} onChange={(e) => this.setState({count: e.target.value})} />
      <button onClick={() => this.start()}>START</button>
      </>
      :
      null
    }
    </div>
  );
  }
}

export default App;
